import { useSearchParams } from 'react-router';
import { Icon, Popover, Pressable, Text, Item, ListBox, FieldError } from '@venncity/block';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import { Divider } from '@venncity/venn-ds';
import clsx from 'clsx';
import { lowerCase, upperFirst } from 'lodash-es';
import React, { useEffect } from 'react';
import { Group, Select, SelectValue } from 'react-aria-components';
import { mapServerAudienceToUI } from '~/dto/notifications-form-dto';
import { enumBroadcastAudienceType } from '~/genql';
import type { BroadcastAudienceType } from '~/genql';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';
import { BuildingsSelector } from './buildings-selector';
import { EventsSelector } from './event-selector';
import { InterestGroupsSelector } from './intrestet-group-selector';
import { ResidentsSelector } from './residents-selector';

const audiencesList = () => {
  return Object.keys(mapServerAudienceToUI).map((key) => {
    return {
      name: mapServerAudienceToUI[key as BroadcastAudienceType],
      id: key,
    };
  });
};
interface AudienceSelectorProps {
  errorMessage: string | undefined | string[];
  defaultSelectedKey: string | undefined;
  defaultSelectedResidents: string[] | undefined;
  defaultSelectedBuildings: string[] | undefined;
  defaultSelectedEvent: string | undefined;
  defaultSelectedInterestGroup: string | undefined;
}

export function AudienceSelector({
  errorMessage,
  defaultSelectedKey,
  defaultSelectedResidents,
  defaultSelectedBuildings,
  defaultSelectedEvent,
  defaultSelectedInterestGroup,
}: AudienceSelectorProps) {
  const [searchParams] = useSearchParams();
  const audienceSelected = searchParams.get('audience') as BroadcastAudienceType;

  const searchNavigate = useSearchNavigate();

  const [selectOpen, setSelectOpen] = React.useState(false);

  useEffect(() => {
    if (!audienceSelected && defaultSelectedKey) {
      searchNavigate({ audience: defaultSelectedKey });
    }
  }, [defaultSelectedKey, audienceSelected, searchNavigate]);

  const communityId = searchParams.get(GlobalSearchParam.CommunityId) || '';

  return (
    <div className="space-y-1">
      <Text weight="bold">*To</Text>
      <Group
        className={clsx(
          'border-grey-500 overflow-hidden rounded-lg border',
          errorMessage && 'border-negative-400 border-1',
          selectOpen && 'border-grey-600',
        )}>
        <SelectAudience
          defaultSelectedKey={defaultSelectedKey || audienceSelected}
          setSelectOpen={setSelectOpen}
        />
        {audienceSelected && audienceSelected !== 'COMMUNITY' && (
          <div className="px-4">
            <Divider marginless />
          </div>
        )}
        {audienceSelected === enumBroadcastAudienceType.SPECIFIC_MEMBERS && (
          <ResidentsSelector defaultSelectedResidents={defaultSelectedResidents} />
        )}
        {audienceSelected === enumBroadcastAudienceType.BUILDING && (
          <BuildingsSelector defaultSelectedKey={defaultSelectedBuildings} />
        )}
        {audienceSelected === enumBroadcastAudienceType.EVENT_TYPE && (
          <EventsSelector defaultSelectedKey={defaultSelectedEvent} />
        )}
        {audienceSelected === enumBroadcastAudienceType.INTEREST_GROUP && (
          <InterestGroupsSelector
            defaultSelectedKey={defaultSelectedInterestGroup}
            key={`interest-groups-${communityId}`}
          />
        )}
      </Group>
      {errorMessage && <FieldError aria-label="Audience">{errorMessage}</FieldError>}
    </div>
  );
}

function SelectAudience({
  setSelectOpen,
  defaultSelectedKey,
}: {
  setSelectOpen: (isOpen: boolean) => void;
  defaultSelectedKey: string | undefined;
}) {
  const searchNavigate = useSearchNavigate();

  return (
    <Select
      aria-label="select audience"
      className="rac-open:bg-grey-100 px-4"
      defaultSelectedKey={defaultSelectedKey}
      key={defaultSelectedKey}
      name="audience"
      onOpenChange={(isOpen) => {
        setSelectOpen(isOpen);
      }}
      onSelectionChange={(audience) => searchNavigate({ audience })}
      placeholder="Select audience">
      <Pressable className="w-full rounded-lg py-4 focus:outline-none">
        <div className="flex w-full items-center justify-between">
          <SelectValue className="rac-placeholder-shown:text-grey-600" />
          <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
            <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
          </span>
        </div>
      </Pressable>
      <Popover offset={2} placement="bottom end">
        <ListBox items={audiencesList()}>
          {({ name }) => <Item>{upperFirst(lowerCase(name))}</Item>}
        </ListBox>
      </Popover>
    </Select>
  );
}
