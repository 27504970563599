import { useSearchParams } from 'react-router';
import {
  BottomSheet,
  DialogTrigger,
  Icon,
  Popover,
  Pressable,
  Text,
  GridList,
  SearchField,
} from '@venncity/block';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import { Button } from '@venncity/venn-ds';
import axios from 'axios';
import clsx from 'clsx';
import { debounce } from 'lodash-es';
import React from 'react';
import type { GridListProps } from 'react-aria-components';
import { useAsyncList } from 'react-stately';
import { ContractStatus } from '~/codegen/graphql';
import type { UserResourceDTO } from '~/dto/user-resource-dto';
import { GlobalSearchParam } from '~/utils/search-params';
import { NoSearchResults } from '../helpers/empty-search-results';
import { EmptyState } from '../helpers/empty-state';
import { useIsMobile } from '../hooks/useIsMobile';

export function ResidentsSelector({
  defaultSelectedResidents,
}: {
  defaultSelectedResidents: string[] | undefined;
}) {
  const isMobile = useIsMobile();
  const itemsCount = isMobile ? 50 : 10;
  const [searchParams] = useSearchParams();

  const communityId = searchParams.get(GlobalSearchParam.CommunityId);

  const list = useAsyncList<UserResourceDTO>({
    async load({ filterText }) {
      const { data } = await axios.get<{ residents: UserResourceDTO[] }>(
        `/resources/notifications/residents/residents?community-id=${communityId}`,
        {
          params: {
            q: filterText,
            itemsCount,
            leaseStatusIn: [ContractStatus.ACTIVE, ContractStatus.FUTURE],
          },
        },
      );

      return { items: data.residents || [] };
    },
  });
  const shouldShowEmptyState = Boolean(
    list.items.length === 0 && !list.filterText && !list.isLoading,
  );

  const [residentsSelected, setResidentsSelected] = React.useState<string[]>(
    defaultSelectedResidents || [],
  );

  React.useEffect(() => {
    if (defaultSelectedResidents) {
      setResidentsSelected(defaultSelectedResidents);
    }
  }, [defaultSelectedResidents]);

  const [mobileNewSelected, setMobileNewSelected] = React.useState<string[]>([]);

  const { searchField, content } = useResidentsGridList({
    items: list.items,
    onInputChange: debounce(list.setFilterText, 400, {
      maxWait: 1000,
      leading: false,
      trailing: true,
    }),
    onSelectionChange: (selection) =>
      isMobile
        ? setMobileNewSelected(Array.from(selection).map(String))
        : setResidentsSelected(Array.from(selection).map(String)),
    ...(isMobile && { defaultSelectedKeys: residentsSelected }),
    ...(!isMobile && { selectedKeys: residentsSelected }),
    isMobile,
    shouldShowEmptyState,
  });

  return (
    <div>
      {residentsSelected?.map((key) => (
        <input key={key} name="residents" type="hidden" value={key.toString()} />
      ))}
      <div className="hidden lg:block">
        <DialogTrigger>
          <ButtonTrigger residentsSelected={residentsSelected} />
          <Popover className="w-[460px]" offset={2}>
            <div className="space-y-2 overflow-y-auto">
              <div className="sticky top-0 w-full bg-white p-3">{searchField}</div>
              <div className="p-3 pt-0">{content}</div>
            </div>
          </Popover>
        </DialogTrigger>
      </div>
      <div className="lg:hidden">
        <DialogTrigger>
          <ButtonTrigger residentsSelected={residentsSelected} />
          <BottomSheet className="h-[95vh]" isDismissable={false}>
            <BottomSheet.Header className="border-transparent pt-4">
              {searchField}
            </BottomSheet.Header>
            <BottomSheet.Body>{content}</BottomSheet.Body>
            <BottomSheet.Footer>
              {({ close }) => (
                <div className="flex w-full space-x-6 bg-white">
                  <Button
                    className="flex-1 [&_span]:flex [&_span]:w-full [&_span]:justify-center"
                    onClick={close}
                    variant="text">
                    Cancel
                  </Button>
                  <Button
                    className="flex-1 [&_span]:flex [&_span]:w-full [&_span]:justify-center"
                    disabled={mobileNewSelected.length === 0 && residentsSelected.length === 0}
                    onClick={() => {
                      setResidentsSelected(mobileNewSelected);
                      close();
                    }}>
                    Save
                  </Button>
                </div>
              )}
            </BottomSheet.Footer>
          </BottomSheet>
        </DialogTrigger>
      </div>
    </div>
  );
}

const useResidentsGridList = ({
  onInputChange,
  shouldShowEmptyState,
  items,
  isMobile,
  ...props
}: GridListProps<UserResourceDTO> & {
  onInputChange: (value: string) => void;
  shouldShowEmptyState?: boolean;
  isMobile: boolean;
}) => {
  const [value, setValue] = React.useState('');

  const searchField = (
    <SearchField
      aria-label="residents search field"
      className={isMobile ? '' : 'bg-white'}
      label="Search a residents..."
      onChange={(e) => {
        setValue(e);
        onInputChange(e);
      }}
      value={value}
    />
  );

  const content = shouldShowEmptyState ? (
    <EmptyState stateType="residents" />
  ) : items && Array.from(items).length < 1 ? (
    NoSearchResults(value)
  ) : (
    <GridList
      className={clsx('max-h-fit', isMobile ? 'pt-0' : 'h-full overflow-y-auto')}
      {...props}
      aria-label="residents selector"
      items={items && Array.from(items)}
      selectionMode="multiple">
      {(item) => (
        <GridList.Item id={item.id} key={item.id} textValue={item.name || ''}>
          {item.name}
        </GridList.Item>
      )}
    </GridList>
  );

  return { searchField, content };
};

function ButtonTrigger({ residentsSelected }: { residentsSelected: string[] }) {
  return (
    <div>
      <Pressable
        aria-label="residents selector"
        className="rac-pressed:bg-grey-50 w-full rounded-lg bg-white p-4 focus:outline-none">
        <div className="flex w-full items-center justify-between">
          {residentsSelected.length > 0 ? (
            <div className="flex flex-col gap-y-2 text-left">
              <Text>{residentsSelected.length} Residents</Text>
            </div>
          ) : (
            <Text className="text-grey-600">Select residents...</Text>
          )}
          <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
            <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
          </span>
        </div>
      </Pressable>
    </div>
  );
}
