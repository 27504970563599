import { useSearchParams } from 'react-router';
import {
  Calendar,
  Icon,
  Item,
  Label,
  ListBox,
  Popover,
  Pressable,
  Text,
  TimeSelection,
  Tooltip,
} from '@venncity/block';
import { ClickableTooltipTrigger } from '@venncity/block/atoms/tooltip/tooltip';
import { Switch, Divider } from '@venncity/venn-ds';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty } from 'lodash-es';
import { AnimatePresence, motion } from 'motion/react';
import React from 'react';
import { DialogTrigger, Select, SelectValue } from 'react-aria-components';
import type { ScheduledBroadcast } from '~/dto/notifications-form-dto';
import { FormSearchParams, NotificationsFormScheduledRepeat } from '~/dto/notifications-form-dto';
import { useAppLoaderData } from '~/utils/common';
import { useSearchNavigate } from '~/utils/search-params';

dayjs.extend(utc);
dayjs.extend(timezone);

export function ScheduledSend({
  defaultScheduledBroadcast,
}: {
  defaultScheduledBroadcast?: ScheduledBroadcast;
}) {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const appLoaderData = useAppLoaderData();

  const [isDatePopoverOpen, setIsDatePopoverOpen] = React.useState(false);

  const [datePickerValue, setDatePickerValue] = React.useState(
    defaultScheduledBroadcast?.startDate ||
      dayjs().tz(appLoaderData.selectedCommunity.timezone).startOf('day').toISOString(),
  );

  const [timeSelectValue, setTimeSelectValue] = React.useState(() => {
    if (defaultScheduledBroadcast?.startDate) {
      return dayjs(defaultScheduledBroadcast?.startDate)
        .tz(appLoaderData.selectedCommunity.timezone)
        .format('HH:mm');
    }

    return dayjs()
      .tz(appLoaderData.selectedCommunity.timezone)
      .add(1, 'hour')
      .minute(0)
      .format('HH:mm');
  });

  const [isExpanded, setIsExpanded] = React.useState(
    Boolean(Number(searchParams.get(FormSearchParams.ScheduledMessage))) ||
      !isEmpty(defaultScheduledBroadcast),
  );

  const [isTimeSelectOpen, setIsTimeSelectOpen] = React.useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const scheduledRepeat =
    defaultScheduledBroadcast?.repeat ||
    searchParams.get(FormSearchParams.Repeat) ||
    NotificationsFormScheduledRepeat.DO_NOT_REPEAT;

  const dateTime = React.useMemo(
    () =>
      dayjs
        .tz(
          `${datePickerValue.toString().split('T')[0]}T${timeSelectValue.toString()}`,
          appLoaderData.selectedCommunity.timezone,
        )
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]'),
    [datePickerValue, appLoaderData.selectedCommunity.timezone, timeSelectValue],
  );

  return (
    <div
      aria-label="Schedule send"
      className="border-grey-500 focus:bg-grey-500 overflow-hidden rounded-lg border-[1px]">
      <div className="flex flex-col px-4">
        <div className="flex justify-between py-4">
          <Label className="flex items-center text-center">Schedule send</Label>
          <Switch
            checked={isExpanded}
            id="isScheduledBroadcast"
            key={`is-scheduled-broadcast-${Number(isExpanded)}`}
            onChange={(value) => {
              searchNavigate({ [FormSearchParams.ScheduledMessage]: value ? '1' : '0' });
              setIsExpanded(value);
            }}
          />
        </div>
        {isExpanded && <Divider marginless />}
      </div>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div animate={{ height: 'auto' }} exit={{ height: 0 }} initial={{ height: 0 }}>
            <input name="scheduled-date" type="hidden" value={dateTime} />
            <div className="flex flex-col">
              <DialogTrigger onOpenChange={setIsDatePopoverOpen}>
                <div
                  className={clsx(
                    'flex justify-between p-4',
                    isDatePopoverOpen && 'bg-grey-50 text-grey-500',
                  )}>
                  <Label className="flex items-center text-center" id="scheduled-date">
                    Select date
                  </Label>
                  <Pressable
                    aria-labelledby="scheduled-date"
                    className="border-grey-900 text-grey-900 rounded-lg border-[1px] px-4 py-1.5 text-sm focus:outline-none">
                    {dayjs(datePickerValue.toString().split('T')[0]).format('MMMM D YYYY')}
                  </Pressable>
                </div>
                <Popover offset={1} placement="top end">
                  <Calendar
                    minValue={dayjs()
                      .tz(appLoaderData.selectedCommunity.timezone)
                      .startOf('day')
                      .toISOString()}
                    onChange={setDatePickerValue}
                    timezone={appLoaderData.selectedCommunity.timezone}
                    value={datePickerValue}
                  />
                </Popover>
              </DialogTrigger>
              <div className="px-4">
                <Divider marginless />
              </div>
            </div>
            <div className="flex flex-col">
              <div
                className={`flex justify-between p-4 ${isTimeSelectOpen ? 'bg-grey-50 text-grey-500' : ''}`}>
                <div className="flex items-center gap-x-1">
                  <Label className="flex items-center text-center">{'Select time'}</Label>
                  <ClickableTooltipTrigger
                    buttonComponent={<Icon className="h-4 w-4" name="help-circle" />}
                    isOpen={isTooltipOpen}
                    setIsOpen={setIsTooltipOpen}>
                    <Tooltip className="ml-1 mt-1" placement="top left" variant="dark">
                      <Text
                        className="flex text-start"
                        slot="description"
                        variant="p5"
                        weight="medium">
                        Scheduling uses the community time zone, ensuring timely delivery regardless
                        of your location.
                      </Text>
                    </Tooltip>
                  </ClickableTooltipTrigger>
                </div>
                <DialogTrigger onOpenChange={setIsTimeSelectOpen}>
                  <Pressable
                    aria-label="Select time"
                    className="border-grey-900 text-grey-900 focus: min-w-[98px] rounded-lg border-[1px] px-4 py-1.5 text-sm outline-none">
                    {convertTo12Hour(timeSelectValue.toString())}
                  </Pressable>
                  <TimeSelection
                    defaultValue={timeSelectValue}
                    key={defaultScheduledBroadcast?.startDate}
                    onSelectionChange={setTimeSelectValue}
                  />
                </DialogTrigger>
              </div>
              <div className="px-4">
                <Divider marginless />
              </div>
            </div>
            <div className="flex flex-col">
              <Select
                aria-label="Repeat"
                className="rac-open:bg-grey-50 rac-open:text-grey-500"
                defaultSelectedKey={scheduledRepeat}
                name="scheduled-repeat"
                onSelectionChange={(value) =>
                  searchNavigate({ [FormSearchParams.Repeat]: value.toString() })
                }>
                <div className="flex justify-between p-4">
                  <Label className="flex items-center text-center">Repeat</Label>
                  <Pressable className="text-grey-900 flex items-center gap-x-1 outline-none">
                    <SelectValue className="text-grey-900 text-p4 font-medium" />
                    <Icon name="chevron-down" />
                  </Pressable>
                </div>
                <Popover offset={1} placement="bottom end">
                  <ListBox>
                    <Item
                      id={NotificationsFormScheduledRepeat.DO_NOT_REPEAT}
                      textValue={NotificationsFormScheduledRepeat.DO_NOT_REPEAT}>
                      Never
                    </Item>
                    <Item
                      id={NotificationsFormScheduledRepeat.WEEKLY}
                      textValue={NotificationsFormScheduledRepeat.WEEKLY}>
                      Weekly on{' '}
                      {dayjs(datePickerValue)
                        .tz(appLoaderData.selectedCommunity.timezone)
                        .format('dddd')}
                    </Item>
                    <Item
                      id={NotificationsFormScheduledRepeat.MONTHLY}
                      textValue={NotificationsFormScheduledRepeat.MONTHLY}>
                      The{' '}
                      {dayjs(datePickerValue)
                        .tz(appLoaderData.selectedCommunity.timezone)
                        .format('Do')}{' '}
                      of every month
                    </Item>
                  </ListBox>
                </Popover>
              </Select>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function convertTo12Hour(timeString: string) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;

  return `${hours12}:${minutes.toString().padStart(2, '0')}${seconds ? `:${seconds.toString().padStart(2, '0')}` : ''} ${period}`;
}
