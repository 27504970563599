import { useNavigate, useSearchParams } from 'react-router';
import {
  Text,
  Drawer,
  Dialog,
  IconButton,
  TooltipTrigger,
  Tooltip,
  Heading,
} from '@venncity/block';
import { Button, Tabs, Avatar } from '@venncity/venn-ds';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '~/lib/analytics';
import type { loader } from '~/routes/resources+/residents+/$id.profile';
import { useResourceData } from '~/utils/http';
import { GlobalDialogType, useSearchNavigate } from '~/utils/search-params';
import { ActivityTab } from './tabs/activity-tab';
import { LeasesTab } from './tabs/leases-tab';

export default function ResidentProfile() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get('resident-id') || '';
  const { data } = useResourceData<typeof loader>(`resources/residents/${residentId}/profile`);
  const searchNavigate = useSearchNavigate();
  const navigate = useNavigate();
  const { t } = useTranslation('resident-profile');

  return (
    <Drawer
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate({
            dialogType: undefined,
            'resident-id': undefined,
          });
        }
      }}>
      <Dialog>
        <Dialog.Header />
        {!data ? (
          <ProfileSkeleton />
        ) : (
          <Dialog.Body className="px-0 py-0 pb-4 md:px-0 md:py-0 md:pb-4">
            <div className="flex flex-col items-center gap-2 px-4">
              <ProfileDetails
                email={data.resident.email}
                image={data.resident.image}
                name={data.resident.name}
                phone={data.resident.phone}
                residentSince={data.resident.residentSince || ''}
              />
            </div>
            <Tabs
              centered
              className="streached"
              id="resident-profile"
              items={[
                {
                  key: 'leases',
                  label: t('tabs.leases'),
                  children: <LeasesTab leaseContracts={data.resident.leaseContractsByStatus} />,
                },
                {
                  key: 'activity',
                  label: t('tabs.activity'),
                  children: (
                    <ActivityTab events={data.resident.events} groups={data.resident?.groups} />
                  ),
                },
              ]}
              size="large"
              tabBarGutter={0}
              tabBarStyle={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            />
          </Dialog.Body>
        )}

        <Dialog.Footer className="empty:hidden">
          {data && data?.featureFlags?.hub_resident_management && (
            <Button
              onClick={() => navigate(`/residents/${residentId}/lease-contracts`)}
              type="primary">
              {t('cta.lease-contracts')}
            </Button>
          )}
          {data && (
            <Button onClick={() => navigate(`/residents/${residentId}/task-lists`)} type="primary">
              {t('cta.task-lists')}
            </Button>
          )}
        </Dialog.Footer>
      </Dialog>
    </Drawer>
  );
}

type ResidentProfileDetailsDTO = {
  image?: string;
  name?: string;
  phone?: string | null;
  email?: string;
  residentSince?: string;
};

function ProfileDetails(residentDetails: ResidentProfileDetailsDTO) {
  const { actionClicked } = useAnalytics();
  const searchNavigate = useSearchNavigate();
  const { t } = useTranslation('resident-profile');

  const handlePress = () => {
    actionClicked({
      element: 'Send update',
    });
    searchNavigate({
      dialogType: GlobalDialogType.CreateResidentNotification,
    });
  };

  return (
    <>
      <Avatar src={residentDetails.image} superSize />
      <Heading className="text-grey-900 mt-2  font-bold" variant={'h3'}>
        {residentDetails.name}
      </Heading>
      {residentDetails.email && (
        <Text className="text-grey-800" variant="p5">
          {residentDetails.email}
        </Text>
      )}
      {residentDetails.phone && (
        <Text className="text-grey-800" variant="p5">
          {residentDetails.phone}
        </Text>
      )}
      {residentDetails.residentSince && (
        <Text className="bg-grey-200 text-grey-600 mt-2 rounded px-1 py-[2px]" variant="p6">
          {residentDetails.residentSince}
        </Text>
      )}
      <TooltipTrigger closeDelay={100} delay={100}>
        <IconButton
          aria-label="Send update"
          className="border-grey-300 hover:border-grey-200 mb-6 mt-2 hidden border md:flex"
          icon="communication"
          onPress={handlePress}
          size="sm"
          variant={'light'}
        />
        <Tooltip className="mb-1.5">
          <Text variant="p6" weight="medium">
            {t('details.cta.send-update')}
          </Text>
        </Tooltip>
      </TooltipTrigger>
      <div className="flex w-[72px] flex-col items-center justify-center gap-2 pt-2 text-center md:hidden">
        <IconButton
          aria-label="Send update"
          className="border-grey-300 rac-pressed:border-grey-500 rac-pressed:bg-grey-200 border"
          icon="communication"
          onPress={handlePress}
          size="sm"
          variant={'light'}
        />
        <Text variant="p5" weight="medium">
          {t('cta.send-update')}
        </Text>
      </div>
    </>
  );
}

function ProfileSkeleton() {
  return (
    <div className="flex animate-pulse flex-col items-center space-y-4 px-4 py-4 md:px-6 md:py-6">
      <div className="bg-grey-400 h-16 w-16 rounded-full" />
      <div className="flex flex-col items-center  gap-4">
        <div className="bg-grey-400 h-5 w-24 rounded" />
        <div className="bg-grey-400 h-5 w-60 rounded" />
      </div>
      <div className="bg-grey-400  h-4 w-36 rounded" />
      <div className="bg-grey-400  h-20 w-full rounded-xl" />
      <div className="*:bg-grey-400 flex w-full gap-5">
        <div className="h-36 w-full rounded-xl" />
      </div>
      <div className="*:bg-grey-400 flex w-full gap-5">
        <div className="h-36 w-full rounded-xl" />
      </div>
    </div>
  );
}

export const handle = {
  i18n: 'resident-profile',
};
