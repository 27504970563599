import { Icon, IconName } from '@venncity/block';
import { Button, Form, Input } from '@venncity/venn-ds';

import axios from 'axios';
import { useAtom } from 'jotai';
import { debounce } from 'lodash-es';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { EventSearchResult, InterestGroupSearchResult, ResidentSearchResult } from '~/genql';
import { useAvailableHubModules } from '~/routes/_app+/_layout/helpers/hooks/use-avail-hub-modules';
import { spotlightActive } from '~/utils/state';

interface ModuleItem {
  id: string;
  icon: IconName;
  label: string;
  tag?: string;
  visible?: boolean;
}

export interface SearchResult {
  searchTerms: string[];
  residents: ResidentSearchResult[];
  events: EventSearchResult[];
  interestGroups: InterestGroupSearchResult[];
  availableModules: ModuleItem[];
}

export function SpotlightSearch() {
  const [formRef] = Form.useForm();
  const [searchResults, setSearchResults] = useState<SearchResult>();
  const [flatSearchResultIds, setFlatSearchResultIds] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(undefined);
  const [isActive, setIsActive] = useAtom(spotlightActive);
  const availableModules = useAvailableHubModules();

  const search = debounce(async (value: string) => {
    if (value.trim() === '') {
      setSearchResults(undefined);
      return;
    }
    value = value.trim();
    const { data } = await axios.get(`/resources/search`, {
      params: { q: value },
    });

    // match any of the search terms to the module label
    const searchTerms = value.toLowerCase().split(' ').filter(Boolean);
    const modulesFound = availableModules.filter((module) =>
      searchTerms.some((term) => module.label?.toLowerCase().includes(term)),
    );

    // combine BE results with available modules
    const results = data as SearchResult;
    setSearchResults({
      searchTerms: searchTerms,
      residents: results.residents,
      events: results.events,
      interestGroups: results.interestGroups,
      availableModules: modulesFound,
    });

    const flattenResultIds = [
      ...results.residents.map((r) => r.userId),
      ...results.events.map((e) => e.id),
      ...results.interestGroups.map((g) => g.id),
      ...modulesFound.map((m) => m.id),
    ];

    setFlatSearchResultIds(flattenResultIds);
    setSelectedItemId(flattenResultIds[selectedIndex]);
  }, 250);

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await search(e.target.value);
  };

  const handleSpotlightKeyboard = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsActive(false);
        setSearchResults(undefined);
        setFlatSearchResultIds([]);
        setSelectedIndex(0);
        setSelectedItemId(undefined);
        formRef.resetFields();
      }

      if (event.key === 'ArrowDown') {
        setSelectedIndex((prev) => {
          const nextIndex = Math.min(prev + 1, flatSearchResultIds.length - 1);
          setSelectedItemId(flatSearchResultIds[nextIndex]);
          return nextIndex;
        });
      }

      if (event.key === 'ArrowUp') {
        setSelectedIndex((prev) => {
          const nextIndex = Math.max(prev - 1, 0);
          setSelectedItemId(flatSearchResultIds[nextIndex]);
          return nextIndex;
        });
      }
    },
    [setIsActive, formRef, flatSearchResultIds, setSelectedItemId],
  );

  React.useEffect(() => {
    if (!isActive) return;

    window.addEventListener('keydown', handleSpotlightKeyboard);

    // cleanup the event listener
    return () => {
      window.removeEventListener('keydown', handleSpotlightKeyboard);
    };
  }, [isActive, handleSpotlightKeyboard]);

  const handleOpenSearchOnCmd_K = React.useCallback(
    (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        // This is to close any open React-Aria modals that are open since they
        // prevent any interaction with ay element when they are opened
        const modalOverlay = document.querySelector("div[data-testid='drawer-overlay']");
        if (modalOverlay) {
          modalOverlay.querySelector('button')?.click();
        }

        setIsActive(true);
      }
    },
    [setIsActive],
  );

  // global key listener to open spotlight
  React.useEffect(() => {
    window.addEventListener('keydown', handleOpenSearchOnCmd_K);

    // cleanup the event listener
    return () => {
      window.removeEventListener('keydown', handleOpenSearchOnCmd_K);
    };
  }, [handleOpenSearchOnCmd_K]);

  React.useEffect(() => {
    if (isActive) {
      // delay until the spotlight is fully opened and part of the DOM
      const timer = setTimeout(() => {
        formRef.focusField('spotlight-search');
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isActive, formRef]);

  if (!isActive) return null;
  return (
    <>
      <div
        onClick={() => {
          const escapeEvent = new KeyboardEvent('keydown', { key: 'Escape' });
          handleSpotlightKeyboard(escapeEvent);
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        className={`fixed inset-0 z-[200] bg-black/70 backdrop-blur-md transition-all duration-150 ease-in-out ${isActive ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
      />
      <div
        className={`fixed left-1/2 top-1/3 z-[300] w-[50%] -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-in-out ${isActive ? 'scale-100 opacity-100' : 'scale-85 pointer-events-none opacity-0'}`}>
        <Form form={formRef}>
          <Form.Item name="spotlight-search" className="z-[400]">
            <Input
              onChange={handleSearch}
              autoComplete="off"
              // prevent up or down to go to input start or end since we will use search results navigation
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                  e.preventDefault();
                }
              }}
              placeholder="Search Anything..."
              size="large"
              className="z-[300] h-14"
            />
          </Form.Item>
        </Form>
        <SearchResults results={searchResults} selectedItemId={selectedItemId} />
      </div>
    </>
  );
}

function SearchResults({
  results,
  selectedItemId,
}: {
  results: SearchResult | undefined;
  selectedItemId: string | undefined;
}) {
  if (!results) return null;

  return (
    <div className="border-grey-400 absolute left-0 right-0 top-full z-10 mt-1 flex flex-col gap-1 rounded-lg border bg-white p-2 drop-shadow-lg">
      {results.residents?.length > 0 && (
        <div className="flex flex-col gap-1">
          <h3 className="text-grey-800 text-left font-semibold">Residents</h3>
          {results.residents.map((result) => (
            <SearchResultItem
              key={result.userId}
              icon="user"
              label={`${result.firstName} ${result.lastName}`}
              link={`/residents?resident-id=${result.userId}&dialog-type=resident-profile`}
              searchTerms={results.searchTerms}
              isSelected={selectedItemId === result.userId}
            />
          ))}
        </div>
      )}

      {results.events?.length > 0 && (
        <div className="flex flex-col gap-1">
          <h3 className="text-grey-800 text-left font-semibold">Events</h3>
          {results.events.map((event) => (
            <SearchResultItem
              key={event.id}
              icon="calendar"
              label={event.name}
              link={`/events?dialog-type=event-form&event-id=${event.id}`}
              searchTerms={results.searchTerms}
              isSelected={selectedItemId === event.id}
            />
          ))}
        </div>
      )}

      {results.interestGroups?.length > 0 && (
        <div className="flex flex-col gap-1">
          <h3 className="text-grey-800 text-left font-semibold">Interest Groups</h3>
          {results.interestGroups.map((grp) => (
            <SearchResultItem
              key={grp.id}
              icon="group"
              label={grp.name}
              link={`/groups/${grp.id}/details?community-id=e6294850-c09b-4a0f-b9fd-eb228e0b93a6`}
              searchTerms={results.searchTerms}
              isSelected={selectedItemId === grp.id}
            />
          ))}
        </div>
      )}

      {results.availableModules?.length > 0 && (
        <div className="flex flex-col gap-1">
          <h3 className="text-grey-800 text-left font-semibold">Modules</h3>
          {results.availableModules.map((module) => (
            <SearchResultItem
              key={module.id}
              icon={module.icon}
              label={module.label}
              link={module.id}
              searchTerms={results.searchTerms}
              isSelected={selectedItemId === module.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface SearchResultItemProps {
  icon: IconName;
  label: string;
  link: string;
  isSelected?: boolean;
  searchTerms: string[];
}

function SearchResultItem({ icon, label, link, isSelected, searchTerms }: SearchResultItemProps) {
  const navigate = useNavigate();

  const handleClick = React.useCallback(async () => {
    await navigate(link);
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
  }, [link, navigate]);

  React.useEffect(() => {
    if (isSelected) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleClick();
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleClick, isSelected]);

  return (
    <div
      tabIndex={-1}
      className={`flex h-10 items-center gap-2 overflow-hidden rounded-md p-2 ${isSelected ? 'bg-stone-400' : ''}`}>
      <Button
        type="link"
        color="default"
        className="!text-grey-800 !px-0"
        icon={<Icon name={icon} />}
        onClick={handleClick}>
        <span dangerouslySetInnerHTML={{ __html: highlightText(label, searchTerms) }} />
      </Button>
    </div>
  );
}

function highlightText(text: string, searchTerms: string[]): string {
  if (!searchTerms.length) return text;
  const regex = new RegExp(`(${searchTerms.join('|')})`, 'gi');
  return text.replace(regex, (match) => `<span style="color: #FC987E;">${match}</span>`);
}
